import { BrowserRouter, Route, Switch } from 'react-router-dom';
import React from 'react';
import AuthorizationCallback from 'common/auth/AuthorizationCallBack';
import { WidgetLoader } from 'WidgetLoader';

const RoutesComponent = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/authorize/callback">
                    <AuthorizationCallback />
                </Route>

                <Route path="/:token">
                    <WidgetLoader />
                </Route>
            </Switch>
        </BrowserRouter>
    );
};

export default RoutesComponent;
