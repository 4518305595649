import { useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { catchError } from 'rxjs';
import { from } from 'rxjs';
import axios from 'axios';
import { b64ToObject } from 'common/util';

const apiGateway = axios.create({
    baseURL: process.env.REACT_APP_API_GATEWAY_URL,
    withCredentials: true,
});

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function getEmail() {
    return b64ToObject(JSON.parse(sessionStorage.getItem('onlive-session-state'))?.token)?.email || '';
}
function getId() {
    return (
        b64ToObject(JSON.parse(sessionStorage.getItem('onlive-session-state'))?.token)?.userId ||
        b64ToObject(JSON.parse(sessionStorage.getItem('onlive-session-state'))?.token)?.actorId
    );
}

function isShoppable() {
    const tokenData = b64ToObject(JSON.parse(sessionStorage.getItem('onlive-session-state'))?.token);
    return tokenData?.type === 'shoppable' || tokenData?.roles?.includes('shoppable');
}

function AuthorizationCallback() {
    const history = useHistory();
    let query = useQuery();

    const handleError = useCallback((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        if (errorCode === 'auth/wrong-password') {
            console.error('Wrong password.', {
                variant: 'error',
            });
        } else {
            console.error(errorMessage, {
                variant: 'error',
            });
        }
        history.push(`/login?state=${query.get('state')}`);
    }, [history]);

    useEffect(() => {
        let state = query.get('state');
        let code = query.get('code');
        if (state && code) {
            const subscription = from(signIn())
                .pipe(
                    catchError(() => {
                        throw new Error('Not authorized');
                    }),
                )
                .subscribe({
                    next: () => true,
                    error: handleError,
                });

            return () => subscription.unsubscribe();
        }
    }, [handleError]);

    const signIn = useCallback(() => {
        return apiGateway.post('oauth/login', { url: window.location.href }).then(({ data }) => {
            if (data.isLoggedIn) {
                return axios
                    .get(
                        'auth/user-info',
                        {
                            baseURL: `${process.env.REACT_APP_API_GATEWAY_URL}/api/v1`,
                            withCredentials: true,
                        },
                    )
                    .then(({ data: userData }) => {
                        if (isShoppable() || userData.user.id === getId()) {
                            localStorage.setItem('userData', JSON.stringify(userData));
                            history.push(`/${JSON.parse(sessionStorage.getItem('onlive-session-state')).token}`);
                            return true;
                        } else {
                            return redirectToOauth();
                        }
                    });
            } else {
                return redirectToOauth();
            }
        });
    }, []);

    const redirectToOauth = useCallback(() => {        
        return apiGateway.post('oauth/auth-url', {
            redirectUri: `${process.env.REACT_APP_CLIENT_URL}/authorize/callback`,
            loginHint: getEmail(),
        })
        .then(({ data }) => { 
            window.location.href = data.authorizationUrl;
            return true;
        });
    }, []);

    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
        }}>
            <style>{`
                @keyframes spinner {
                    0% {
                        transform: rotate(0deg);
                    }
                    100% {
                        transform: rotate(360deg);
                    }
                }
            `}</style>
            <div style={{
                width: 40,
                height: 40,
                border: '2px solid #000000',
                borderBottomColor: '#FF0000',
                borderRadius: '50%',
                display: 'inline-block',
                boxSizing: 'border-box',
                animation: `spinner 0.75s linear infinite`,
            } }/>
        </div>
    );
}

export default AuthorizationCallback;
